import React from "react";
import Header from "../components/molecules/header";
import Footer from "../components/molecules/footer";
import Banner from "../components/molecules/banner";
import Form from "../components/molecules/form";
import Head from "../components/organisms/head";

export default function contactUs() {
  const moduleData = {
    title: "Connecting people with knowledge",
  };
  const data ={
    title:'Contact Us',
    description:'Connecting people with knowledge',
    image:'',
    keywords:''
}

  return (
    <>
      <Header />
      <div>
        <div>
            <Form />
        </div>
      </div>
      <Footer />
    </>
  );
}
