import React, {useState} from "react";
import styles from "./ContactForm.module.scss";
import { StaticImage } from "gatsby-plugin-image";
import axios from "axios";
import validator from "validator";

export default function Form() {
  const [email, setEmail] = useState("");
  const [fullName, setFullName] = useState("");
  const [subject, setSubject] = useState("");
  const [success, setSuccess] = useState(false);

  const [emailError, setEmailError] = useState("");
  const [errors, setErrors] = useState({});

  const validateField = (name, value) => {
    let errorMsg = "";
    if (value.trim() === "") {
      errorMsg = `${name} is required.`;
    } else if (name === "Email" && !validator.isEmail(value)) {
      errorMsg = "Invalid email address.";
    }
    setErrors(prevErrors => ({ ...prevErrors, [name]: errorMsg }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "email":
        setEmail(value);
        break;
      case "fullName":
        setFullName(value);
        break;
      case "subject":
        setSubject(value);
        break;
      default:
        break;
    }
    validateField(name, value);
  };

  const clearFields = () => {
    setEmail('');
    setFullName('');
    setSubject('');
  }

  const handleSubmit = async () => {
    validateField("Email", email);
    validateField("Full Name", fullName);
    validateField("Subject", subject);

    if (!errors.Email && !errors["Full Name"] && !errors.Subject) {
      await axios({
        method: "post",
        url: "https://api.sendinblue.com/v3/smtp/email",
        data: {
          "sender":{
            "name": fullName,
            "email": email
          },
          "to":[
            {
              "email":"info@learnio.eu",
              "name":"Learnio"
            }
          ],
          "subject":"Learnio contact form",
          "htmlContent":"<html>" +
              "<body><p>Hello from " + fullName + ",</p>" +
              "<p>" + subject + "</p>" +
              "<hr/>" +
              "<p>This message has been sent from Learnio.</p>" +
              "</body>" +
              "</html>"
        },
        headers: {
          "Content-Type": "application/json",
          "api-key":
              "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",
        },
      })
          .then(function (response) {
            setSuccess(true);
            setEmail("");
            setFullName("");
            setSubject("");
            clearFields();
          })
          .catch(function (response) {
            setEmailError("Something went wrong!");
            //handle error
          });
    }
  };

  return (
    <div className={styles.formConteiner}>
      <div className={styles.content}>
        <div className={styles.description}>
          <h3>Contact us</h3>
          <p>Want to know more?</p>

          <form>
            <div className={styles.inputHolder}>
              <label>Full Name</label>
              <input
                  name="fullName"
                  type="text"
                  placeholder="Enter your full name"
                  value={fullName}
                  onChange={handleInputChange}
              />
             {errors["Full Name"] && <p className={styles.error}>{errors["Full Name"]}</p>}
            </div>
            <div className={styles.inputHolder}>
              <label>Email</label>
              <input
                  name="email"
                  type="text"
                  placeholder="Enter your email"
                  value={email}
                  onChange={handleInputChange}
              />
               {errors.Email && <p className={styles.error}>{errors.Email}</p>}
            </div>
            <div className={styles.inputHolder}>
              <label>Message</label>
              <textarea
                  name="subject"
                  type="text"
                  placeholder="Write your message"
                  value={subject}
                  onChange={handleInputChange}
              />
               {errors.Subject && <p className={styles.error}>{errors.Subject}</p>}
            </div>
            <a style={{cursor: "pointer"}} onClick={() => handleSubmit()}>Send</a>
          </form>
          {
            success && (
                <p className={styles.error}>Message successfully sent.</p>
            )
          }
        </div>
        <div className={styles.info}>
          <div>
            <div>
              <p>Phone</p>
              <span className={styles.number}>Norway Office: +47 - 93 20 88 78</span>
              <span className={styles.number}>Kosovo Office: +38344740201</span>
              <span className={styles.number}>Bangladesh Office: +8801754936508</span>
            </div>
            <div>
              <p>Contact</p>
              <span>info@learnio.eu</span>
            </div>
            <div>
              <p>Address</p>
              <span>Learnio <br/>
Part of MASTER WiZR <br/>Vardeveien 3 <br />1363 Hovik <br/>Norway</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
